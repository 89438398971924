import React from "react"

//import components
import HomePage from '../components/HomePage'
import Hero from '../components/Hero'
import Gallery from '../components/Gallery'
import About from '../components/About'
import Footer from '../components/Footer'
import Padding from '../components/Padding'
import GithubLink from '../components/GithubLink'

//import styles
import '../styles/global.css'

const Home = () => (
        <HomePage>
            <Hero />
            <Padding />
            <Gallery />
            <Padding size={'s'}/>
            <GithubLink />
            <Padding />
            <About />
            <Padding />
            <Footer />
        </HomePage>
    )

export default Home