import React from "react"

//import components
import Head from './Head'
import NavBar from './NavBar'
import MobileNav from "../components/MobileNav";

export default ({ children }) => (
  <div>
    <Head />
    <NavBar isHomepage={true}/>
    <MobileNav />
    {children}
    </div>
)