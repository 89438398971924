import React from 'react'

//import components
import Line from './Line'

//import styles
import heroStyles from './Hero.module.css'

//import images
import newLine from '../images/home-page/scrollLine2.gif'

export default () => (
    <div className={heroStyles.hero}>
        <div className={heroStyles.container}>
            <div>
                <h1>hello.</h1>
                <Line color={'white'}/>
                <h2>I'M SOPHIE</h2>
                <p>Data Visualization Artist & Graphic Designer</p>
            </div>
        </div>
        <img className={heroStyles.scrollLine} src={newLine} alt="scroll to see more" />
    </div>
)