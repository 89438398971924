import React from 'react'

//import styles
import styles from './GithubLink.module.css'

//import images
import githubImage from '../images/logos/github.svg'

export default ({background=false}) => {
    const backgroundStyle = {
        background: 'rgba(145,35,85,.14)',
        padding: '30px 0'
    }
    return (
        <div
            className={styles.github}
            style={background ? backgroundStyle : null}
        >
            <img src={githubImage} alt={'Github logo'} />
            <p>For more HTML/CSS designs, visit my <a href={'https://github.com/sophievakalis'} target='_blank' rel="noopener noreferrer">Github</a></p>
        </div>
    )
}