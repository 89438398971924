import React, { useEffect, useState } from 'react'
import {Link} from 'gatsby'

//import components
import Line from './Line'

//import styles
import aboutStyles from './About.module.css'

//import images
import gifPlaceholder from '../images/home-page/chart-redo_2020_placeholder.png'
import interestsGif from '../images/home-page/chart-redo_2020.gif'

export default () => {
    const [viewable, setViewable] = useState(false)

    let interests
    if (typeof document !== 'undefined'){
        interests = document.getElementsByClassName(aboutStyles.interests)
    }
        
    const handleScroll = () => {
        const countdown = interests["0"].offsetTop - window.scrollY - window.innerHeight + interests["0"].clientHeight
        if (countdown < 0) {
            setViewable(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })

    return (
        <section className={aboutStyles.about}>
        <div className={aboutStyles.interests}>
            <Line color={'black'} />
            <h2>DESIGN INTERESTS</h2>
            <div className={aboutStyles.gif}>
                <img src={viewable ? interestsGif : gifPlaceholder} alt="" />
            </div>
        </div>
        <div className={aboutStyles.sophie}>
            <Line color={'black'} />
            <h2>SOPHIE</h2>
            <p>I am a graphic designer who believes in the power of thoughtful and informative design.</p>
            <p>My work experience began with internships at a nonprofit organization and marketing agency, where I observed and contributed to a variety of projects. I continued, expanding into the visual merchandising world. During this time I focused on branding, adhering to style guides while keeping the target audience in mind. Seeing the effectiveness design can have on customers led me to think more about how it can help communicate non-commercial information, which is how my love for information design emerged.</p>
            <p>From 2018–2019 I completed my Masters in Graphic Design with a focus in Data Visualization Design from the University of Portsmouth in England. I was able to build upon my design knowledge and experiment with effective approaches for optimal viewer engagement. Check out my final project <Link to='/visual-narratives'>here</Link>.</p>
            <p>My goal is to continue in this field of design while exploring the world of interface and experience design.</p>
        </div>
        </section>
    )
    }